<!--  -->
<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item label="场地名称">
          <el-select v-model="search.siteId" clearable filterable placeholder="请选择场地">
            <el-option
              v-for="item in sites"
              :key="item.id"
              :label="item.areaname"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="选择社区">
          <el-select
            v-model="search.communityCode"
            clearable
            placeholder="请选择社区"
          >
            <el-option
              v-for="item in communitys"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名称">
          <el-input v-model="search.createman" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="search.checkflag" clearable filterable placeholder="请选择审核状态">
            <el-option
              v-for="item in checkFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe>
      <el-table-column prop="createmanname" label="用户名称"> </el-table-column>
      <el-table-column prop="areaname" label="场地名称"> </el-table-column>
      <el-table-column prop="address" label="场地地址"> </el-table-column>
      <el-table-column prop="areaflag" label="场地状态" :formatter="areaflagFormatter"> </el-table-column>
      <el-table-column prop="dateStr" label="预约日期"> </el-table-column>
      <el-table-column prop="orderTime" label="预约时间"> </el-table-column>
      <el-table-column prop="points" label="收取积分"> </el-table-column>
      <el-table-column prop="checkflag" label="审核状态" :formatter="checkflagFormatter"> </el-table-column>
      <el-table-column prop="checkmsg" label="审核描述"> </el-table-column>
      <el-table-column label="操作" fixed="right" width="280" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.checkflag != 1 && scope.row.checkflag != 2"
            @click="auditRow(scope.row)"
            type="text"
            size="small">
            <i class="el-icon-tickets"></i> 审核
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>
    <!-- 审核表单 -->
		<el-dialog title="场地预约审核" width="700px" :visible.sync="dialogFormVisible" :before-close="beforeClose" append-to-body>
			<el-form :model="form"  label-width="120px" style="width: 90%;">
				<el-form-item label="审核结果">
					<el-select v-model="form.isPass" clearable placeholder="请选择审核结果">
						<el-option
              v-for="item in resultFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核意见">
					<el-input type="textarea" clearable v-model="form.opinion" placeholder="请输入审核意见"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">提交审核</el-button>
    			<el-button @click="cancleFn">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
  </div>
</template>

<script>
import { queryPage, auditOrder } from '@/api/siteOrderAudit'
import { querySitePage } from "@/api/acitvity/index.js";
import { communityOfUser } from '@/api/system';
import { sureMessageBox }　from '@/utils/common'
export default {
  data () {
    return {
      sites: [],
      communitys: [],
      checkFlags: [
        {
          label: '待审核',
          value: '0'
        },
        {
          label: '审核通过',
          value: '1'
        },
        {
          label: '审核不通过',
          value: '2'
        }
      ],
      search: {
        siteId: '',
        communityCode: '',
        createman: '',
        checkflag: '',
      },
      tableData: [],
      currentPage: 1,
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      dialogFormVisible: false,
      form: {
        id: '',
        isPass: '',
        opinion: '',
      },
      resultFlags:[
        {
          label: '审核通过',
          value: '1'
        },
        {
          label: '审核不通过',
          value: '2'
        }
      ],
    };
  },

  components: {},

  computed: {},

  mounted(){
    this.getCommunityOfUser();
    this.getSitesList();
    this.getList();
  },

  methods: {
    //获取当前登录用户的管辖社区
    getCommunityOfUser(){
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //获取场地列表
    getSitesList() {
      querySitePage({
        userid: this.GLOBAL.adminId(),
        pagesize: 99999,
        page: 1,
      }).then(res => {
        if (res.result == 200) {
          let detail = res.detail;
          this.sites = detail.list;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      queryPage({
        communityCode: this.search.communityCode,
        siteId: this.search.siteId,
        createman: this.search.createman,
        checkflag: this.search.checkflag,
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage
      }).then(res => {
        if (res.result == 200) {
          let detail = res.detail;
          this.fenye.totalnum = detail.totalRow;
          detail.list.map(item => {
            let startTime = item.start_time;
            let endTime = item.end_time;
            item.dateStr = startTime?startTime.substring(0, 10):'';
            item.orderTime = (startTime && endTime)?(startTime.substr(-5) + '-' + endTime.substr(-5)):'';
          })
          this.tableData = detail.list;
        } else {
          this.$message.error(res.description);
        }
      });
    },
    areaflagFormatter(row) {
      if(row.areaflag == 0) {
        return '启用中'
      } else if(row.areaflag == 1) {
        return '停用中'
      }
    },
    checkflagFormatter(row) {
      if(row.checkflag == 1) {
        return '审核通过'
      } else if(row.checkflag == 2) {
        return '审核不通过'
      } else {
        return '待审核'
      }
    },
    //切换页面条数
    handleSizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    auditRow(row) {
      this.form.id = row.id;
      this.dialogFormVisible = true;
    },
    //点击关闭按钮重置表单内容
		beforeClose(done){
			this.cancleFn();
			done();
		},
    //取消审核
		cancleFn(){
			this.dialogFormVisible = false;
			this.form = {
				isPass: '',
				opinion: ''
			}
		},
    onSubmit() {
      if(this.form.isPass === ''){
				this.$message.warning('请选择审核结果');
				return false;
			}
			if(this.form.opinion === ''){
				this.$message.warning('请输入审核意见');
				return false;
			}
      sureMessageBox('是否确认提交审核？').then(res=>{
        auditOrder({
          id: this.form.id,
          isPass:  this.form.isPass,
          opinion: this.form.opinion,
          userId: this.GLOBAL.adminId()
        }).then(res => {
          if(res.result == 200){
            this.$message.success('审核成功')
            this.getList();
            this.cancleFn();
          }else{
            this.$message.warning(res.description)
          }
        }).catch(err=>{
          console.log(err)
        })
      })
    },
  }
}

</script>
<style lang='scss' scoped>
.searchbox {
  margin: 0 10px;
}
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
</style>